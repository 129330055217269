import { Box, Typography } from "@mui/material";
import React from "react";
import {
  title,
  subheading,
  subheading2,
  para1,
  para2,
  subheading3,
  para3,
  subheading4,
  para4,
} from "../../seeds/sponsor-us.page";

const SponsorUsContext = () => {
  return (
    <>
      <Box textAlign="center">
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Typography variant="h5">{subheading}</Typography>
      <Typography variant="h6">{subheading2}</Typography>
      <Typography variant="body1">{para1}</Typography>
      <Typography variant="h6">{subheading3}</Typography>
      <Typography variant="body1">{para2}</Typography>
      <Typography variant="body1">{para3}</Typography>
      <Typography variant="h6">{subheading4}</Typography>
      <Typography variant="body1">{para4}</Typography>
    </>
  );
};

export default SponsorUsContext;
