import { Box, Drawer, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isDesktop } from "../utils/utils";

// interface RightFullPageDrawerProps {
//   open: boolean;
//   drawerClose: () => void;
//   drawerTitle: string;
//   children: React.ReactNode;
//   footer?: React.ReactNode;
//   allowOverflow?: boolean;
// }

const RightFullPageDrawer = (props) => {
  const {
    open,
    drawerClose,
    drawerTitle,
    children,
    footer,
    allowOverflow = false,
    ...otherprops
  } = props;

  const isDesktopView = isDesktop();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={drawerClose}
      {...otherprops}
      sx={{
        "& .MuiDrawer-paper": {
          width: isDesktopView ? "20vw" : "-webkit-fill-available",
          height: "100vh",
          p: 2,
          overflow: allowOverflow ? "auto" : "visible",
        },
      }}
      transitionDuration={300}
    >
      <>
        {/* Drawer header start */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            my: 4,
            bgcolor: "background.paper",
          }}
        >
          <Typography variant="h4" component="div">
            {drawerTitle}
          </Typography>
          <CloseIcon
            onClick={drawerClose}
            sx={{
              cursor: "pointer",
            }}
          />
        </Stack>
        {/* Drawer header end */}
        {children}
        {/* Drawer footer start */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "-webkit-fill-available",
            zIndex: 1000,
            bgcolor: "background.paper",
          }}
        >
          {footer}
        </Box>
        {/* Drawer footer end */}
      </>
    </Drawer>
  );
};

export default RightFullPageDrawer;
