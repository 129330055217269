import React from "react";
import { Box } from "@mui/material";
import HomeBanner from "../public/images/Home-Banner.jpeg";
import HomeBannerMobile from "../public/images/Home-Banner-Mobile.jpg";

const Jumborton = () => {
  return (
    <Box
      sx={{
        backgroundImage: [`url(${HomeBannerMobile})`, `url(${HomeBanner})`],
        backgroundSize: "cover",
        height: ["calc(70vh - 68px)", "calc(85vh - 68px)"],
        width: "100%",
      }}
    />
  );
};

export default Jumborton;
