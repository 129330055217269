import { Alert, Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import EventCard from "../../components/EventCard";
import Loading from "../../general-components/Loading";

const EventsPageDesktopLayout = (props) => {
  const {
    categories_rows,
    EventsRows,
    eventType,
    handleChange,
    handleClick,
  } = props;
  return (
    <Box sx={{ mt: "100px" }}>
      <Grid container>
        <Grid item xs={3}>
          <Box
            sx={{
              border: "1px solid grey",
              borderRadius: "8px",
              m: "8px",
              p: "16px",
            }}
          >
            <Typography variant="h5">Events</Typography>
            <Box>
              <Chip
                sx={{ m: "4px 4px 4px 0px", textTransform: "capitalize" }}
                label="all events"
                onClick={handleChange}
                variant={eventType === "all events" ? "filled" : "outlined"}
              />
              {categories_rows &&
                categories_rows.map((categories) => (
                  <Chip
                    sx={{ m: "4px 4px 4px 0px", textTransform: "capitalize" }}
                    label={categories.title.toLowerCase()}
                    onClick={handleChange}
                    variant={
                      eventType === categories.title.toLowerCase()
                        ? "filled"
                        : "outlined"
                    }
                  />
                ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {EventsRows ? (
              EventsRows.length > 0 ? (
                EventsRows.map((event) => {
                  return (
                    <Box
                      sx={{
                        m: "8px",
                      }}
                    >
                      <EventCard
                        title={event.title}
                        date={event.date}
                        imageLink={event.imageLink}
                        description={event.description}
                        cardWidthDesktop={300}
                        primaryActionLabel={"Book now"}
                        handleClick={() => handleClick(event.link, event.title)}
                      />
                    </Box>
                  );
                })
              ) : (
                <Box sx={{ my: "8px", mx: "8px" }}>
                  <Alert severity="error">
                    {`There are no ${eventType} events available to view. Keep checking our website to stay up to date.`}
                  </Alert>
                </Box>
              )
            ) : (
              <Loading />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventsPageDesktopLayout;
