import {
  Alert,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import React from "react";
import EventCard from "../../components/EventCard";
import Loading from "../../general-components/Loading";

const label = "filter event type";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EventsPageMobileLayout = (props) => {
  const {
    categories_rows,
    EventsRows,
    eventType,
    handleChange,
    handleClick,
  } = props;
  return (
    <Box sx={{ mt: "100px" }}>
      <Stack>
        <Box
          sx={{
            m: "8px 21px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              label={label}
              id="demo-multiple-chip"
              value={eventType}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label={label} />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    key={selected}
                    label={selected}
                    sx={{ textTransform: "capitalize" }}
                  />
                </Box>
              )}
              MenuProps={MenuProps}
            >
              <MenuItem
                key={"all events"}
                value={"all events"}
                sx={{ textTransform: "capitalize" }}
              >
                {"all events"}
              </MenuItem>
              {categories_rows &&
                categories_rows.map((categories) => (
                  <MenuItem
                    key={categories.title.toLowerCase()}
                    value={categories.title.toLowerCase()}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {categories.title.toLowerCase()}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          {EventsRows ? (
            EventsRows.length > 0 ? (
              EventsRows.map((event) => {
                return (
                  <Box
                    sx={{
                      m: "8px",
                    }}
                  >
                    <EventCard
                      fullwidth
                      title={event.title}
                      date={event.date}
                      description={event.description}
                      imageLink={event.imageLink}
                      primaryActionLabel={"Book now"}
                      handleClick={() => handleClick(event.link, event.title)}
                    />
                  </Box>
                );
              })
            ) : (
              <Box sx={{ mt: "16px", mx: "20px" }}>
                <Alert severity="error">
                  {`There are no ${eventType} events available to view. Keep checking our website to stay up to date.`}
                </Alert>
              </Box>
            )
          ) : (
            <Loading />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default EventsPageMobileLayout;
