import { Stack } from "@mui/material";
import React from "react";
import SponsorUsPackages from "../components/sponsor-us/sponsor-us-packages";
import SponsorUsContext from "../components/sponsor-us/sponsor-us-context";

const BecomeOurSponsor = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        padding: 3,
      }}
    >
      <SponsorUsContext />
      <SponsorUsPackages />
    </Stack>
  );
};

export default BecomeOurSponsor;
