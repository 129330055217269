import React from "react";

import { Box } from "@mui/material";

import MiniAboutUs from "../components/MiniAboutUs";

const About = ({aboutUsData}) => {
  return (
    <>
      <Box sx={{ mt: "100px" }}>
        <MiniAboutUs aboutUsData={aboutUsData} />
      </Box>
    </>
  );
};

export default About;
