export const isMobile = () => {
  return window.screen.width <= 900;
};

export const isDesktop = () => {
  return window.screen.width > 900;
};

export const scrollToTop = () => {
  return window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export const setEventTypeInLocalStorage = (eventType) => {
  const isFromNavbar = eventType.toLowerCase() === "events";
  if (isFromNavbar) {
    localStorage.removeItem("eventType");
  } else {
    localStorage.setItem("eventType", eventType.toLowerCase());
  }
};

export const convertSheetDataToList = (data) => {
  return data?.length > 0 ? data[0]?.data : [];
};
export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorage = (key) => {
  const data = sessionStorage.getItem(key);
  if (!data) return null;
  return JSON.parse(data);
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const shouldCallGoogleSheetsApi = () => {
  const eventsData = getSessionStorage("events");
  const aboutUsData = getSessionStorage("aboutUs");
  const categoriesData = getSessionStorage("categories");
  const sponsorsData = getSessionStorage("sponsors");
  const footerData = getSessionStorage("footer");
  const satrangImageCarouselData = getSessionStorage("satrangImageCarousel");

  return (
    !eventsData ||
    !aboutUsData ||
    !categoriesData ||
    !sponsorsData ||
    !footerData ||
    !satrangImageCarouselData
  );
};
