import { useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Menu,
  Button,
  AppBar,
  Toolbar,
  MenuItem,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../public/images/logo.png";

import { setEventTypeInLocalStorage, scrollToTop } from "../utils/utils";
import RightFullPageDrawer from "../general-components/right-full-page-drawer.component";

const pages = [
  { label: "SatRang Theatre", link: "/satrang" },
  { label: "Sponsor us", link: "/sponsor-us" },
  { label: "About us", link: "/about" },
  { label: "Events", link: "/events" },
  { label: "Home", link: "/" },
];

const StyledImage = styled("img")({
  width: "150px",
  height: "auto",
});

const ResponsiveAppBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openRightDrawer = () => {
    setDrawerOpen(true);
  };

  const closeRightDrawer = () => {
    setDrawerOpen(false);
  };

  const handleCloseUserMenu = () => {
    scrollToTop();
    closeRightDrawer();
  };

  return (
    <AppBar sx={{ minHeight: "64px", backgroundColor: "#3A4750" }}>
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            <Link to="/" onClick={scrollToTop}>
              <StyledImage src={logo} alt="" />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openRightDrawer}
              color="inherit"
              sx={{ "&:focus": { outline: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            
            <RightFullPageDrawer
              open={drawerOpen}
              drawerClose={closeRightDrawer}
              drawerTitle={"LaaL Button"}
            >
              {pages.map((page) => {
                let handleClick = () => {
                  handleCloseUserMenu();
                };
                if (page.link.includes("/events")) {
                  handleClick = () => {
                    handleCloseUserMenu();
                    setEventTypeInLocalStorage(page.label);
                    scrollToTop();
                  };
                }
                return (
                  <Link
                    to={page.link}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "inherit",
                    }}
                  >
                    <MenuItem key={page} onClick={handleClick}>
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  </Link>
                );
              })}
            </RightFullPageDrawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
