import React from "react";

import { Box } from "@mui/material";

import Carousel from "../components/Carousel";
import Jumborton from "../components/Jumborton";
import Categories from "../components/Categories";
import SponsorsBar from "../components/SponsorsBar";

const Home = ({ categoriesData, eventsData, sponsorsData }) => {
  return (
    <>
      <Jumborton />
      <Box sx={{ mt: 4 }}>
        <Carousel list={eventsData} />
      </Box>
      <Box sx={{ mt: 7 }}>
        <Categories categoriesData={categoriesData} />
      </Box>
      <Box sx={{ mt: 7 }}>
        <SponsorsBar sponsorsData={sponsorsData} />
      </Box>
    </>
  );
};

export default Home;
