import React from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Grid,
  List,
  Link as MuiLink,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import logo from "../public/images/logo.png";

import { setEventTypeInLocalStorage, scrollToTop } from "../utils/utils";

const StyledImage = styled("img")({
  width: "250px",
  height: "auto",
  objectFit: "contain",
});

const StyledList = ({ list }) => {
  return list ? (
    <List>
      {list.map((listItem) => {
        let handleClick = () => {};
        if (listItem.list === "events") {
          handleClick = () => {
            setEventTypeInLocalStorage(listItem.label);
            scrollToTop();
          };
        }
        if (listItem.list === "organization") {
          handleClick = () => {
            scrollToTop();
          };
        }
        return (
          <Link
            onClick={handleClick}
            key={listItem.label}
            to={listItem.link}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "inherit",
              display: "block",
              width: "max-content",
            }}
          >
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  pl: 0,
                }}
              >
                <ListItemText primary={listItem.label} />
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </List>
  ) : (
    <List>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            pl: 0,
          }}
        >
          <ListItemText primary="Trash" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component="a"
          href="#simple-list"
          sx={{
            pl: 0,
          }}
        >
          <ListItemText primary="Spam" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

const Footer = (props) => {
  const { footerData } = props;
  const rows = footerData ? footerData : [];

  return (
    <Box
      color="white"
      sx={{
        pt: ["0px", "20px"],
        pb: "20px",
      }}
    >
      <Box
        sx={{
          p: "16px",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                pl: ["unset", "32px"],
              }}
            >
              <StyledImage src={logo} alt="" />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                display: "flex",
                justifyContent: ["space-between", "space-around"],
                mt: "32px",
                mx: ["16px", "unset"],
              }}
            >
              <Box sx={{ width: "130px" }}>
                <Typography variant="h5">Orgainzation</Typography>
                <StyledList
                  list={rows.filter((row) => row.list === "organization")}
                />
              </Box>

              <Box sx={{ width: ["unset", "130px"] }}>
                <Typography variant="h5">Follow us</Typography>
                <StyledList
                  list={rows.filter((row) => row.list === "socials")}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center">
        <Typography variant="caption" sx={{ fontSize: "14px" }}>
          Designed by{" "}
          <MuiLink
            sx={{ color: "white", textDecoration: "none" }}
            href="http://minteksoftware.com"
          >
            Mintek Software
          </MuiLink>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
