import React from "react";
import { useNavigate } from "react-router-dom";

import { tabsClasses } from "@mui/material/Tabs";
import { Box, Tab, Tabs, Typography } from "@mui/material";

import Loading from "../general-components/Loading";
import AboutUsCard from "./AboutUsCard";

import { scrollToTop, setEventTypeInLocalStorage } from "../utils/utils";
import EventCard from "./EventCard";

const Categories = (props) => {
  const { categoriesData } = props;

  const rows = categoriesData;

  const navigate = useNavigate();
  const handleClick = (eventType) => {
    setEventTypeInLocalStorage(eventType);
    navigate(`/events`);
    scrollToTop();
  };

  if (typeof rows === "undefined" || typeof rows === "null") return <Loading />;

  return (
    <Box textAlign="center">
      <Typography variant="h2">Event Categories</Typography>
      <Box
        sx={{
          m: "auto",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              background: "none !important",
            },
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 1 },
            },
            mx: ["auto", 5],
          }}
        >
          {rows &&
            rows.map((row) => {
              let tmpLabel = (
                <EventCard
                  title={row.title}
                  imageLink={row.image}
                  description={row.description}
                  primaryActionLabel={"View events"}
                  handleClick={() => handleClick(row.title)}
                  cardContentHeight={90}
                />
              );
              return (
                <Tab key={row.title} label={tmpLabel} disableRipple={true} />
              );
            })}
        </Tabs>
      </Box>
    </Box>
  );
};

export default Categories;
