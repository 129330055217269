import React from "react";
import { Box, Typography } from "@mui/material";

import AboutUsCard from "./AboutUsCard";

import { isMobile } from "../utils/utils";

const MiniAboutUs = (props) => {
  const { aboutUsData } = props;

  const rows = aboutUsData;
  const title = `About us`;

  if (isMobile()) {
    return (
      <div>
        <Box textAlign="center">
          <Typography variant="h2">{title}</Typography>
        </Box>
        {rows &&
          rows.map((row) => {
            return (
              <Box
                sx={{
                  display: ["flex"],
                  justifyContent: "space-around",
                  alignItems: "center",
                  m: "16px",
                }}
              >
                <AboutUsCard
                  title={row.title}
                  imageLink={row.image}
                  description={row.text}
                />
              </Box>
            );
          })}
      </div>
    );
  }

  return (
    <div>
      <Box textAlign="center">
        <Typography variant="h2">{title}</Typography>
      </Box>
      {rows &&
        rows.map((row, index) => {
          let aboutUsComp =
            index % 2 === 0 ? (
              <Box
                sx={{
                  display: ["flex"],
                  justifyContent: "space-around",
                  alignItems: "center",
                  m: "24px",
                }}
              >
                <Box sx={{ width: ["100%", "50%"] }}>
                  <Typography variant="h5" sx={{ textAlign: "left" }}>
                    {row.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: "8px",
                    }}
                  >
                    {row.text}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "30%",
                  }}
                >
                  <img
                    style={{
                      width: "-webkit-fill-available",
                      borderRadius: "14px",
                    }}
                    src={row.image}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: ["flex"],
                  justifyContent: "space-around",
                  alignItems: "center",
                  m: "24px",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                  }}
                >
                  <img
                    style={{
                      width: "-webkit-fill-available",
                      borderRadius: "14px",
                    }}
                    src={row.image}
                  />
                </Box>
                <Box sx={{ width: ["100%", "50%"] }}>
                  <Typography variant="h5" sx={{ textAlign: "left" }}>
                    {row.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: "8px",
                    }}
                  >
                    {row.text}
                  </Typography>
                </Box>
              </Box>
            );

          return aboutUsComp;
        })}
    </div>
  );
};

export default MiniAboutUs;
