import React, { useEffect } from "react";

import { Box } from "@mui/material";

const SponsorsBar = (props) => {
  const { sponsorsData } = props;
  const [imgLink, setImgLink] = React.useState("");

  useEffect(() => {
    const mainImage = sponsorsData?.find(
      (sponsor) => sponsor.title.toLowerCase() === "main image"
    );
    setImgLink(mainImage?.imageLink);
  }, [sponsorsData]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        mb: -2,
      }}
    >
      <img
        src={imgLink}
        style={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
        }}
        alt="sponsors"
      />
    </Box>
  );
};

export default SponsorsBar;
