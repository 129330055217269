export const title = `Be our sponsor`;
export const subheading = `The aim of LaaLButton is to involve and benefit two groups`;

export const subheading2 = `Youth volunteers and business sponsors:`;

export const para1 = `
It is an event organized for the youth, by the youth. we provide an
opportunity to both groups to gain from the festival for our youth in
the gta, it is an opportunity to give back to the community and
volunteer in an event where they will learn universal soft skills such
as interpersonal, leadership & organizational. For business sponsors,
Laalbutton provides an opportunity that allows local, national &
international businesses to connect with the gta consumer base through
various media and marketing platforms.
`;

export const subheading3 = `For our sponsors:`;

export const para2 = `
In addition to this event being a great way to witness & enjoy a fun
energy packed show with friends and family, it is also a prime
opportunity for you as a potential sponsor to effectively market your
brand & business leading up to & during the event by marketing your
brand through our shows/festivals. You will be able to connect with
people in the community who can potentially become customers of your
business.
`;

export const para3 = `
You will have the opportunity to present new innovations, build
awareness among a relevant audience & showcase expertise offered through
your product and/or service. By sponsoring events/festivals, business
owners can take advantage of addressing thousands of people about their
brand by directly speaking to them at workshops, meetings, and the
competition itself. To be exact we have almost 800 people connected with
us on Instagram. In 2022 we are expanding that reach to three additional
regions including Guelph, Niagara and Surrey. The possibilities of
connecting with people in unique ways are endless.
`;

export const subheading4 = `SatRang Theatre`;

export const para4 = `
Our target age group falls in any age group above 16 years of age. This
includes people of South Asian origins who speak Punjabi, Hindi, Urdu
and/or English. Geographically speaking it will include people from
Brampton, Mississauga, Milton, Toronto, and Caledon.
`;

const laalButtonPackagePara1 = `
Our sponsorship is based on setting up a long term relationship so we can figure out ways to work together and help your business grow.
Every month we put together at least 3 to 4 events with audiences that are young and most are new immigrants within different communities 
such as Punjabi and Hindi speaking communities.`;

const laalButtonPackagePara2 = `Let’s say you want to do a promotion with use for 6 months. 
Within that promotion we will hand pick the events which will work best for your organization and design a promotional plan that suits your needs. 
You may end up promoting your event at our monthly open mics which will fall into the basic category and you will get 6 slots at $250 per slot ($1500). 
Along with that let’s say you pick TheDesi Mixtape Series and we end up providing you 4 slots for that at $370 each slot for a total of ($1480). 
You will end up paying $2980 for the promotion with total savings of $620.`;

const laalButtonPackagePara3 = `Along with this you will get 5 free tickets to share with your clients, friends or family for each of the events
we are promoting you at. That’s an additional savings of $75 per event.`;

export const laalButtonPackageDescription = `${laalButtonPackagePara1}<br><br>${laalButtonPackagePara2}<br><br>${laalButtonPackagePara3}`;
