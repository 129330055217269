import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  List,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import image from "../../../public/images/satrang24Event2.png";
import { satrang24TicketLinks } from "../../../seeds/satrang.page";

const Event2 = (props) => {
  const { openNewTab = () => {} } = props;
  return (
    <Card sx={{ height: "100%", position: ["unset", "relative"] }}>
      <CardMedia
        sx={{ height: [200, 700] }}
        image={image}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Khol Do
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Khol Do, a story by Saadat Hasan Manto first published in 1948 in the
          collection "Black Margins” ( Siyah Hashiye) begins with Sirajuddin, an
          elderly man in a Lahore refugee camp, searching for his missing
          daughter, Sakina. After young male volunteers assure him they will
          find her, they locate Sakina but rape her, leading to her death.
          Sirajuddin later finds her corpse in a hospital, and the story ends
          with a doctor ordering, "Khidki khol do" (open the window),
          highlighting the grim reality of her fate.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Team:
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body1" color="text.secondary">
            Directed by: Jagdish Chandra Tiwari
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Writer: Saadat Hasan Manto
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Actor: Vivek Sharma
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Singer & Musicians: Jashan Panesar and Major Singh Takhar
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Lights & Sound: Sabina Singh
          </Typography>
        </Stack>
      </CardContent>
      <CardActions
        sx={{
          position: ["unset", "absolute"],
          bottom: 0,
        }}
      >
        <Button
          size="small"
          onClick={() => {
            openNewTab(satrang24TicketLinks);
          }}
        >
          Get tickets
        </Button>
        <Button
          size="small"
          onClick={() => {
            openNewTab(satrang24TicketLinks);
          }}
        >
          Register
        </Button>
      </CardActions>
    </Card>
  );
};

export default Event2;
