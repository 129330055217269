import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import satrang23Poster from "../../../public/images/Satrang23.jpeg";
import Event1 from "./event1";
import Event2 from "./event2";

const Satrang24 = () => {
  return (
    <Accordion sx={{ mt: 5 }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h4">SatRang Theatre 2023</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={satrang23Poster}
              alt="satrang23"
              style={{
                width: "inherit",
                height: "inherit",
              }}
            />
          </Box>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Events
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ p: 1 }}>
              <Event1 />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ p: 1 }}>
              <Event2 />
            </Grid>
          </Grid>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default Satrang24;
