import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ImageCarousel = (props) => {
  const { images } = props;
  return (
    <>
      {images.length > 0 && (
        <Carousel
          autoPlay
          stopOnHover
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          transitionTime={2000}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.imgURL}
                alt={image.imgAlt}
                style={{
                  maxHeight: "70vh",
                  objectFit: "contain",
                }}
              />
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default ImageCarousel;
