import React from "react";
// import satrang-package-img from `../../assets/satrang-package.png`
import satrangPkg from "../../public/images/sponsor-us.satrang.png";
import laalButtonPkg from "../../public/images/sponsor-us.laal-button.png";
import customPkg from "../../public/images/sponsor-us.custom.png";
import { Stack } from "@mui/material";
import SponsorUsPackageCard from "./sponsor-us-package.card";
import { laalButtonPackageDescription } from "../../seeds/sponsor-us.page";
import { isDesktop } from "../../utils/utils";

const SponsorUsPackages = () => {
  const isDesktopView = isDesktop();
  return (
    <Stack spacing={2}>
      <SponsorUsPackageCard
        title={"Sat-Rang sponsorship package"}
        imageLink={satrangPkg}
        imgHeight={isDesktopView ? 500 : 300}
        imgWidth="-webkit-fill-available"
      />
      <SponsorUsPackageCard
        title={"LaaLButton sponsorship package"}
        imageLink={laalButtonPkg}
        description={laalButtonPackageDescription}
        imgHeight={isDesktopView ? "-webkit-fill-available" : 'unset'}
        imgWidth="-webkit-fill-available"
      />
      <SponsorUsPackageCard
        title={"Custom sponsorship package"}
        imageLink={customPkg}
        imgHeight={isDesktopView ? "-webkit-fill-available" : 'unset'}
        imgWidth="-webkit-fill-available"
      />
    </Stack>
  );
};

export default SponsorUsPackages;
