import React, { useState } from "react";

import { isDesktop } from "../../utils/utils";
import { useEffect } from "react";
import EventsPageDesktopLayout from "./events-desktop.layout";
import EventsPageMobileLayout from "./events-mobile.layout";
import { callAnalytics } from "../../utils/amplitude";

const Event = ({ categoriesData, eventsData }) => {
  let events_rows = eventsData;
  const categories_rows = categoriesData;

  const [eventType, setEventType] = useState("all events");
  const [EventsRows, setEventsRows] = useState(events_rows);

  useEffect(() => {
    if (eventsData) {
      setEventType("all events");
      setEventsRows(eventsData);
    }
  }, [events_rows, eventsData]);

  const handleChange = (event) => {
    const target = event.target;
    let eventType;
    if (target.innerText) {
      setEventType(target.innerText);
      eventType = target.innerText;
    } else if (target.value) {
      setEventType(target.value);
      eventType = target.value;
    }
    if (eventType) {
      eventType === "all events"
        ? setEventsRows(events_rows)
        : setEventsRows(
            events_rows.filter(
              (event) =>
                event.category.toLowerCase() === eventType.toLowerCase()
            )
          );
    }
  };

  const handleClick = (eventLink, eventTitle) => {
    callAnalytics("buy_now_clicked", {
      eventTitle,
    });
    window.open(eventLink);
  };

  return isDesktop() ? (
    <EventsPageDesktopLayout
      categories_rows={categories_rows}
      EventsRows={EventsRows}
      eventType={eventType}
      handleChange={handleChange}
      handleClick={handleClick}
    />
  ) : (
    <EventsPageMobileLayout
      categories_rows={categories_rows}
      EventsRows={EventsRows}
      eventType={eventType}
      handleChange={handleChange}
      handleClick={handleClick}
    />
  );
};

export default Event;
