import React, { useEffect, useState } from "react";
import useGoogleSheets from "use-google-sheets";
import { convertSheetDataToList, setSessionStorage } from "../../utils/utils";
import Loading from "../../general-components/Loading";

const ConditionallyFetchFromSheets = (props) => {
  const { callbackToRunAfterFetchingData } = props;
  const [eventsData, setEventsData] = useState([]);
  const [aboutUsData, setAboutUsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [sponsorsData, setSponsorsData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [satrangImageCarouselData, setSatrangImageCarouselData] = useState([]);

  const { data: fetchedEventsData } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "events" }],
  });

  const { data: fetchedAboutUsData } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "aboutUs" }],
  });

  const { data: fetchedCategoriesData } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "categories" }],
  });

  const { data: fetchedSponsorsData } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "sponsors" }],
  });

  const { data: fetchedFooterData } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "footer" }],
  });

  const { data: fetchedSatrangImageCarouselData } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: "satrang-image-carousel" }],
  });

  useEffect(() => {
    const parsedData = convertSheetDataToList(fetchedEventsData);
    if (parsedData.length > 0) {
      setEventsData(parsedData);
      setSessionStorage("events", parsedData);
    }
  }, [fetchedEventsData]);

  useEffect(() => {
    const parsedData = convertSheetDataToList(fetchedAboutUsData);
    if (parsedData.length > 0) {
      setAboutUsData(parsedData);
      setSessionStorage("aboutUs", parsedData);
    }
  }, [fetchedAboutUsData]);

  useEffect(() => {
    const parsedData = convertSheetDataToList(fetchedCategoriesData);
    if (parsedData.length > 0) {
      setCategoriesData(parsedData);
      setSessionStorage("categories", parsedData);
    }
  }, [fetchedCategoriesData]);

  useEffect(() => {
    const parsedData = convertSheetDataToList(fetchedSponsorsData);
    if (parsedData.length > 0) {
      setSponsorsData(parsedData);
      setSessionStorage("sponsors", parsedData);
    }
  }, [fetchedSponsorsData]);

  useEffect(() => {
    const parsedData = convertSheetDataToList(fetchedFooterData);
    if (parsedData.length > 0) {
      setFooterData(parsedData);
      setSessionStorage("footer", parsedData);
    }
  }, [fetchedFooterData]);

  useEffect(() => {
    const parsedData = convertSheetDataToList(fetchedSatrangImageCarouselData);
    if (parsedData.length > 0) {
      setSatrangImageCarouselData(parsedData);
      setSessionStorage("satrangImageCarousel", parsedData);
    }
  }, [fetchedSatrangImageCarouselData]);

  useEffect(() => {
    if (
      eventsData.length > 0 &&
      aboutUsData.length > 0 &&
      categoriesData.length > 0 &&
      sponsorsData.length > 0 &&
      footerData.length > 0 &&
      satrangImageCarouselData.length > 0
    ) {
      callbackToRunAfterFetchingData();
    }
  }, [
    eventsData,
    aboutUsData,
    categoriesData,
    sponsorsData,
    footerData,
    satrangImageCarouselData,
  ]);

  return <Loading />;
};

export default ConditionallyFetchFromSheets;
