import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  List,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import image from "../../../public/images/satrang24Event1.png";
import { satrang24TicketLinks } from "../../../seeds/satrang.page";

const Event1 = (props) => {
  const { openNewTab = () => {} } = props;
  return (
    <Card>
      <CardMedia
        sx={{ height: [200, 700] }}
        image={image}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lucknow express 0224
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Set against the vibrant backdrop of the 1980s is a delightful and
          entertaining comedy that unfolds within the confines of a train, where
          two distinct and humorous stories play out in the train compartments.
        </Typography>
        {/* <List>
          <Typography variant="h6">Acts:</Typography>
          <ListItemText
            sx={{ color: "text.secondary" }}
            primary="Ek Istri Ek Purush (एक स्त्री एक पुरुष)"
            secondary="Written by Dr. Maheep Singh"
            primaryTypographyProps={{ variant: "body2" }}
            secondaryTypographyProps={{ variant: "body2" }}
          />
          <ListItemText
            sx={{ color: "text.secondary" }}
            primary="Tottma (टोटमा)"
            secondary="Written by Harimohan jha"
            primaryTypographyProps={{ variant: "body2" }}
            secondaryTypographyProps={{ variant: "body2" }}
          />
        </List> */}
        <Typography variant="h6" sx={{ mt: 1 }}>
          Team:
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body1" color="text.secondary">
            Writer: Dr. Maheep Singh and Harimohan Jha respectively.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Directed by: Vivek Sharma
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Set designer: Karan Sheth
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Lights & Sound: Sabina Singh
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Actors: Sandeep Maheep Singh , Ganesh Gujar , Mehakdeep Gill , Kapil
            Chhabra , Jagroop Singh , Meenu , Anuradha Quanoongo , Om Manu
            Ajmani , Neha Kappal , Jujhar Kaur, Pradeep Singh ( Ravi) , Aman
            Thakur , Ravneet Kaur , Balkar Gill , Kalpana Vaze , Bhavesh Kothari
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            openNewTab(satrang24TicketLinks);
          }}
        >
          Get tickets
        </Button>
        <Button
          size="small"
          onClick={() => {
            openNewTab(satrang24TicketLinks);
          }}
        >
          Audition
        </Button>
      </CardActions>
    </Card>
  );
};

export default Event1;
