import * as React from "react";

import {
  Card,
  Button,
  CardMedia,
  Typography,
  CardActions,
  CardContent,
  CardActionArea,
} from "@mui/material";

import logo from "../public/images/logo.png";

import { isDesktop } from "../utils/utils";

const AboutUsCard = (props) => {
  const {
    title,
    date,
    imageLink,
    description,
    primaryActionLabel,
    secondaryActionLabel,
    fullwidth,
    height,
    handleClick = () => {},
  } = props;
  return isDesktop() ? (
    <Card
      sx={{
        width: fullwidth ? "93%" : ["250px", "350px"],
        m: fullwidth ? "auto" : "8px",
      }}
      onClick={handleClick}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          sx={{
            height: height ? [`140px`, `${height}px`] : "200px",
            objectFit: "contain",
          }}
          src={imageLink}
          alt={logo}
        />
        <CardContent sx={{ textAlign: "left", height: "175px" }}>
          {title && (
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body2"
              textAlign="justify"
              sx={{
                height: "50px",
                lineHeight: "1.6",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
              }}
            >
              <summary></summary>
              {description}
            </Typography>
          )}
          {date && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: "16px" }}
            >
              {date}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        {primaryActionLabel && (
          <Button size="small" color="primary" onClick={handleClick}>
            {primaryActionLabel}
          </Button>
        )}
        {secondaryActionLabel && (
          <Button size="small" color="primary">
            {secondaryActionLabel}
          </Button>
        )}
      </CardActions>
    </Card>
  ) : (
    <Card
      sx={{
        mb: "16px",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <CardActionArea>
        <CardMedia component="img" src={imageLink} alt={title} />
        <CardContent sx={{ textAlign: "left" }}>
          {title && (
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" textAlign="justify">
              {description}
            </Typography>
          )}
          {date && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: "16px" }}
            >
              {date}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        {primaryActionLabel && (
          <Button size="small" color="primary" onClick={handleClick}>
            {primaryActionLabel}
          </Button>
        )}
        {secondaryActionLabel && (
          <Button size="small" color="primary">
            {secondaryActionLabel}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default AboutUsCard;
