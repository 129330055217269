import * as amplitude from "@amplitude/analytics-browser";

export const initAmplitude = () => {
  if (process.env.NODE_ENV === "development") {
    return;
  }
  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY);
};

export const callAnalytics = (eventName, eventProperties) => {
  if (process.env.NODE_ENV === "development") {
    console.log("Analytics event: ", eventName, eventProperties);
    return;
  }
  amplitude.track(eventName, eventProperties);
};
