import * as React from "react";

import {
  Card,
  Button,
  CardMedia,
  Typography,
  CardActions,
  CardContent,
  CardActionArea,
} from "@mui/material";

import default_event from "../public/images/default_event.png";

const EventCard = (props) => {
  const {
    title,
    date,
    imageLink,
    description,
    primaryActionLabel,
    secondaryActionLabel,
    fullwidth,
    imgHeight,
    cardWidthDesktop = 350,
    cardWidthMobile = 250,
    handleClick = () => {},
    containImage = false,
    cardContentHeight = 175,
  } = props;
  return (
    <Card
      sx={{
        width: fullwidth
          ? "93%"
          : [`${cardWidthMobile}px`, `${cardWidthDesktop}px`],
        m: fullwidth && "auto",
      }}
      onClick={handleClick}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          sx={{
            height: imgHeight ? [`140px`, `${imgHeight}px`] : "200px",
            objectFit: containImage ? ["cover", "contain"] : "cover",
          }}
          src={imageLink}
          alt={default_event}
        />
        <CardContent
          sx={{ textAlign: "left", height: `${cardContentHeight}px` }}
        >
          {title && (
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body2"
              sx={{
                height: "60px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "3",
                "-webkit-box-orient": "vertical",
              }}
            >
              {description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {primaryActionLabel && (
          <Button size="small" color="primary" onClick={handleClick}>
            {primaryActionLabel}
          </Button>
        )}
        {date && (
          <Typography variant="body1" color="text.secondary">
            {date}
          </Typography>
        )}
      </CardActions>
    </Card>
  );
};

export default EventCard;
