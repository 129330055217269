import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import satrang24Poster from "../../../public/images/Satrang24.jpg";
import Event1 from "./event1";
import Event2 from "./event2";
import { isDesktop } from "../../../utils/utils";

const Satrang24 = () => {
  const openNewTab = (link) => {
    window.open(link);
  };

  const isDesktopView = isDesktop();

  return (
    <Accordion sx={{ mt: 5 }} defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h4">SatRang Theatre 2024</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={satrang24Poster}
              alt="satrang24"
              style={{
                width: "100%",
                height: isDesktopView ? '500px' :"100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Typography variant="h4">Acts for this year</Typography>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ p: 1 }}>
              <Event1 openNewTab={openNewTab} />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ p: 1 }}>
              <Event2 openNewTab={openNewTab} />
            </Grid>
          </Grid>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default Satrang24;
