const paragraph1 = `
“SatRang-South Asian Theatre Rangmanch” is an initiative of “Laal Button” to provide a bigger & unique platform to South Asian performing artists. 
SatRang is one of its kind Theater Festival that provides an opportunity for Brampton’s performance artists to come together & create a unique showcase stories.
`;

const paragraph2 = `
This festival will emphasize on building & strengthening strong cultural grounds for artists as well as the South Asian community through locally bred stories.
`;

const paragraph3 = `
The project will serve the South Asian demographic which accounts to a significant population in the Brampton area. 
This will encourage writers, actors, directors, musicians to collaborate on a common project and showcase their talents.
`;

const paragraph4 = `
The impetus of this theater festival is to provide a common platform to local writers, actors, directors & various artists to 
present their original work in any genre/South Asian language. 
This festival will help in promoting social & cultural integration amongst different communities & artists. 
SatRang will encourage a culture of theater, which is helpful for artistic expression & cultural enrichment.
`;

const paragraph5 = `
We do aim to bring entertainment to the audience through our stories, 
but we also aim to use this platform as a medium to touch themes & stories that can bring a social change & awareness. 
We want to push the envelope & we hope that our efforts will take the performers & audience on a journey of exploration where everybody comes out with a 
better & new understanding of the subject matter (social issues).
`;

const paragraph6 = `
SatRang will bring together South Asian creatives to deliver original work & bring together artists from diverse groups together. 
Further it creates a platform for artists to share common aspects of Brampton’s life, nurture skills, and drive innovation in their practices. 
This project addresses the concern for Brampton to have a vibrant, culturally thoughtful program that will increase engagement of a new group of people, 
i.e South Asian residents, which has been steadily growing. 
For an artist it is a platform for expression that is both powerful and unique and for an audience it can be an enriching experience of watching 
something created in their own city. Something that truly speaks to them!
`;

export const title = "SatRang Theatre";

export const satrangDescription = `${paragraph1}<br><br>${paragraph2}<br><br>${paragraph3}<br><br>${paragraph4}<br><br>${paragraph5}<br><br>${paragraph6}`;

export const satrang24TicketLinks = "https://tickets.brampton.ca/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=8CAB06DE-063A-4527-81B8-71FC0AF3B446&BOparam::WScontent::loadArticle::context_id=3AE1668F-1C68-4F76-B013-93A2109013B7"
