import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

const SponsorUsPackageCard = (props) => {
  const { title, imageLink, description, imgHeight, imgWidth } = props;
  return (
    <Stack spacing={2}>
      {title && <Typography variant="h6">{title}</Typography>}
      {imageLink && (
        <img
          src={imageLink}
          alt={title}
          style={{
            height: imgHeight,
            width: imgWidth,
            objectFit: "contain",
          }}
        />
      )}
      {description && (
        <Typography dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Stack>
  );
};

export default SponsorUsPackageCard;
