import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import image from "../../../public/images/satrang23event1.jpeg";

const Event1 = () => {
  return (
    <Card>
      <CardMedia sx={{ height: [140, 500] }} image={image} title="green iguana" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Sarkari maut
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Event1;
